import React, { useState } from 'react';
import { formatPrice } from '../helper/useContracts';
import { useCommonStats, useAccountStats } from '../hooks/useStats';
import { useWeb3React } from '@web3-react/core';
import Button from 'react-bootstrap-button-loader';
import { parseEther } from '@ethersproject/units';
import { toast } from 'react-toastify';
import { getContract } from '../helper/contractHelper';
import stakeAbi from '../json/tokenstake.json';
import tokenAbi from '../json/token.json';
import { contract } from '../helper/contract';
import { getWeb3 } from '../helper/connectors';
import MyStake from './MyStake';
import { ethers } from 'ethers';



export default function Stake() {

    const daysTab = {
        links: [
            {
                id: 1,
                className: "StakeTablinks",
                content: "30 Days",
                apy: "20",
                timestamp: "30"
            },
            {
                id: 2,
                className: "StakeTablinks",
                content: "60 Days",
                apy: "40",
                timestamp: "60"
            },
            {
                id: 3,
                className: "StakeTablinks",
                content: "120 Days",
                apy: "80",
                timestamp: "120"
            },
            {
                id: 4,
                className: "StakeTablinks",
                content: "180 Days",
                apy: "120",
                timestamp: "180"

            },
            {
                id: 5,
                className: "StakeTablinks",
                content: "360 Days",
                apy: "200",
                timestamp: "360"

            }
        ],
        activeLink: null
    };

    const { chainId, account, library } = useWeb3React();
    const [dayId, setDayId] = useState(1);
    const [updater, setUpdater] = useState(new Date());
    const stats = useCommonStats(updater);
    const accStats = useAccountStats(updater);
    const [apy, setApy] = useState(daysTab.links[0].apy);
    const [btndisabled, setBtndisabled] = useState(false);
    const [amount, setAmount] = useState(0);
    const [error_msg, setError_msg] = useState('');
    const timeElapsed = Date.now();
    const [endtime, setEndtime] = useState(new Date(parseInt(timeElapsed) + parseInt(daysTab.links[0].timestamp * 86400 * 1000)));
    const [loading, setLoading] = useState(false);

    const setUpdaterA = () => {
        setUpdater(new Date());
    }

    const handleChangeDay = (id) => {
        setDayId(id);
        setApy(daysTab.links[id - 1].apy)
        setEndtime(new Date(parseInt(timeElapsed) + parseInt(daysTab.links[id - 1].timestamp * 86400 * 1000)))
    }

    const handleChangeAmount = (e) => {
        setAmount(e.target.value);
        setBtndisabled(true);

        if (isNaN(e.target.value)) {
            setError_msg('Please enter valid amount');
            setBtndisabled(true);
        }

        else if (parseFloat(e.target.value) === 0 || e.target.value === '') {
            setError_msg('Amount must be greater than zero');
            setBtndisabled(true);
        }
        else {
            setError_msg('');
            setBtndisabled(false);
        }
        return;
    }

    const handleApprove = async (e) => {
        e.preventDefault();
        if (account) {
            if (chainId) {
                try {
                    setLoading(true);

                    let tokenAddress = contract[chainId] ? contract[chainId].tokenAddress : contract['default'].tokenAddress;
                    let tokenStakingAddress = contract[chainId] ? contract[chainId].stakingAddress : contract['default'].stakingAddress;

                    let tokenContract = getContract(tokenAbi, tokenAddress, library);
                    let amount = parseEther('100000000000000000000000').toString();
                    let tx = await tokenContract.approve(tokenStakingAddress, amount, { 'from': account });
                    const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                    toast.promise(
                        resolveAfter3Sec,
                        {
                            pending: 'Waiting for confirmation 👌',
                        }
                    )
                    var interval = setInterval(async function () {
                        let web3 = getWeb3(chainId);
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.success('success ! your last transaction is success 👍');
                                setUpdater(new Date());
                                setLoading(false);
                            }
                            else if (response.status === false) {
                                toast.error('error ! Your last transaction is failed.');
                                setUpdater(new Date());
                                setLoading(false);
                            }
                            else {
                                toast.error('error ! something went wrong.');
                                setUpdater(new Date());
                                setLoading(false);
                            }
                        }
                    }, 5000);

                }
                catch (err) {
                    toast.error(err.reason ? err.reason : err.message);
                    setLoading(false);
                }
            }
            else {
                toast.error('Please select Smart Chain Network !');
                setLoading(false);
            }

        }
        else {
            toast.error('Please Connect Wallet!');
            setLoading(false);
        }
    }

    const handleStake = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            if (amount > 0 && !isNaN(e.target.value)) {
                if (account) {
                    if (chainId) {
                        if (parseFloat(accStats.balance) >= parseFloat(amount)) {
                            if (typeof dayId !== 'undefined' || dayId !== 0) {
                                let lockupDuration = daysTab.links[dayId - 1].timestamp;
                                let tokenStakingAddress = contract[chainId] ? contract[chainId].stakingAddress : contract['default'].stakingAddress;
                                let stakeContract = getContract(stakeAbi, tokenStakingAddress, library);
                                let stakeAmount = ethers.utils.parseUnits(amount.toString() , stats.tokenDecimal);

                                let tx = await stakeContract.deposit(stakeAmount.toString(), lockupDuration, { 'from': account });
                                const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                                toast.promise(
                                    resolveAfter3Sec,
                                    {
                                        pending: 'Waiting for confirmation 👌',
                                    }
                                )

                                var interval = setInterval(async function () {
                                    let web3 = getWeb3(chainId);
                                    var response = await web3.eth.getTransactionReceipt(tx.hash);
                                    if (response != null) {
                                        clearInterval(interval)
                                        if (response.status === true) {
                                            toast.success('success ! your last transaction is success 👍');
                                            setUpdater(new Date());
                                            setLoading(false);
                                        }
                                        else if (response.status === false) {
                                            toast.error('error ! Your last transaction is failed.');
                                            setUpdater(new Date());
                                            setLoading(false);
                                        }
                                        else {
                                            toast.error('error ! something went wrong.');
                                            setUpdater(new Date());
                                            setLoading(false);
                                        }
                                    }
                                }, 5000);
                            }
                            else {
                                toast.error('Please select days !');
                                setLoading(false);
                            }
                        }
                        else {
                            toast.error('you don\'t have enough balance !');
                            setLoading(false);
                        }

                    }
                    else {
                        toast.error('Please select Smart Chain Network !');
                        setLoading(false);
                    }
                }
                else {
                    toast.error('Please Connect Wallet!');
                    setLoading(false);
                }
            }
            else {
                toast.error('Please Enter Valid Amount !');
                setLoading(false);
            }
        }
        catch (err) {
            toast.error(err.reason ? err.reason : err.message);
            setLoading(false);
        }
    }


    


    return (
        <React.Fragment>
            {/* Breadcrumbs Section Start --> */}
            <div className="gamfi-breadcrumbs-section ProjectClasicBreadCumSect governance_breadcrumbs_scetion">
                <div className="governance_breadcrumbs_content">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="breadcrumbs-area sec-heading">
                                    <h2 className="title mb-0 blue-color">Stake APT </h2>
                                </div>
                            </div>
                            <div className="col-lg-7 breadcrumbs-form md-pt-30">
                                <div className="staking_bradcome_right">
                                    <ul>
                                        <li>
                                            <h6>Total Stake</h6>
                                            <h5><span className="counter">{formatPrice(stats.totalStake)}</span> APT</h5>
                                        </li>
                                        <li>
                                            <h6>APY</h6>
                                            <h5><span className="counter">200</span>% (UPTO)</h5>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Breadcrumbs Section End --> */}

            {/* Participat Detaisl Content Start --> */}
            <div className="participat-information project-details-conent gamfi-about-secion pb-80 md-pb-50">
                <div className="container">
                    <div className="row pt-0">
                        <div className="col-lg-6 pr-25 md-pr-15">
                            <div className="project-item">
                                <div className="project-info m-0">
                                    <h4 className="mb-56">Participate Stake</h4>
                                </div>
                                <ul className="date-listing mb-35" id="staking_preiod_btn" key="uniwsdhb">
                                    {daysTab.links.map(link => {
                                        return (
                                            <>
                                                <li key={link.id} onClick={(e) => { handleChangeDay(link.id) }} className={link.className + (link.id === dayId ? " active" : "")} id="defaultOpen">
                                                    <button key={link.id} >{link.content}</button>
                                                </li>

                                            </>
                                        )
                                    })
                                    }
                                </ul>
                                <div className="project-content">
                                    <div id="sevenDays" className="StakeTabcontent">
                                        <div className="project-media mb-40">
                                            <ul className="project-listing">
                                                <li><span>APY Rate</span></li>
                                                <li><strong></strong> <a href="#sec"><span className="big-text">{apy} %</span></a></li>
                                                <li>Early unstake fee: <strong>{stats.emergencyWithdrawFees}%</strong></li>
                                                <li>locked until {endtime.toUTCString()}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="project-form-list">
                                    <h4>Amount to Stake</h4>
                                    <div className="balance-form-area mb-1">
                                        <input type="text" value={amount} onChange={(e) => { handleChangeAmount(e) }} placeholder="Enter Amount" />
                                        <button type='button' className="max" >MAX</button>

                                        {parseFloat(accStats.isApprove) <= 1000000000 ? (
                                            <div className="white-shape-small approve" onClick={(e) => handleApprove(e)}>
                                                <Button loading={loading} type="button">APPROVE</Button>
                                                <span className="hover-shape1"></span>
                                                <span className="hover-shape2"></span>
                                                <span className="hover-shape3"></span>
                                            </div>
                                        ) : (
                                            <div className="white-shape-small approve">
                                                <Button disabled={btndisabled} loading={loading} type="button" onClick={(e) => handleStake(e)} >STAKE</Button>
                                                <span className="hover-shape1"></span>
                                                <span className="hover-shape2"></span>
                                                <span className="hover-shape3"></span>
                                            </div>
                                        )}

                                    </div>
                                    <h5 className='text-danger mb-47'><small>{error_msg}</small></h5>

                                    <h6 className="mb-9">Balance&nbsp; : &nbsp;
                                        <span>{formatPrice(accStats.balance)} APT</span>
                                        <a target="_blank" rel="noreferrer" href="https://pancakeswap.finance/swap?outputCurrency=0x31cBD2123CBd141fE8270d7c91bA571E1489b4A3">Buy Now
                                        </a>
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 pl-25 md-pl-15">
                            <div className="project-item project-value-inner d-flex justify-content-between align-items-center mb-30">
                                <div className="project-value">
                                    <h3 className="mb-15">{formatPrice(accStats.totalStake)} APT</h3>
                                    <h6>Staked Amount</h6>
                                </div>
                            </div>
                            <div className="project-item project-value-inner d-flex justify-content-between align-items-center">
                                <div className="project-value">
                                    <h3 className="mb-15">{formatPrice(accStats.totalRewardEarn)} GET</h3>
                                    <h6>Rewards Earned</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Participat Detaisl Content End --> */}


            <MyStake updater={updater}  setUpdater={setUpdaterA}  />
        </React.Fragment>
    )
}
