import React from 'react'
// import logoFImg from '../images/logo-footer.png';
import steps2Img from '../images/steps2.png';
import stepsImg from '../images/steps.png';

export default function Footer() {
    return (
        <>
            {/* Footer Section Start  */}
            <div className="gamfi-footer-section">
                {/* <div className="container">
                    <div className="footer-cta-area text-center active-shape hover-shape-inner">
                        <h2 className="title mb-15">
                            Apply for project<br />
                            incubation
                        </h2>
                        <div className="dsc mb-40 md-mb-30">
                            If you want to launch an ICO/IDO, It will be your perfect choice
                        </div>
                        <Link  to="/ico-apply" className="banner-btn wow fadeInUp black-shape" data-wow-delay="0.3s" data-wow-duration="0.5s" >
                            <span className="btn-text">Apply</span>
                            <span className="hover-shape1"></span>
                            <span className="hover-shape2"></span>
                            <span className="hover-shape3"></span>
                        </Link>
                        <span className="border-shadow shadow-1"></span>
                        <span className="border-shadow shadow-2"></span>
                        <span className="border-shadow shadow-3"></span>
                        <span className="border-shadow shadow-4"></span>
                        <span className="hover-shape-bg hover_shape1"></span>
                        <span className="hover-shape-bg hover_shape2"></span>
                        <span className="hover-shape-bg hover_shape3"></span>
                    </div>
                </div> */}
                <div className="footer-area">
                    <div className="container">
                        <div className="sec-heading text-center">
                            <div className="sub-inner mb-52 mb-mb-30">
                                <img className="heading-right-image" src={steps2Img} alt="Steps31" />
                                <span className="sub-title">Find us on Social</span>
                                <img className="heading-left-image" src={stepsImg} alt="Steps21" />
                            </div>
                        </div>
                        <div className="footer-listing text-center mb-100 md-mb-70 xs-mb-50">
                            <ul className="footer-icon-list">
                                <li>
                                    <a href="https://thesudochain.com" target="_blank" rel="noreferrer">
                                        <i className="icon-world"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://t.me/sudochain" target="_blank" rel="noreferrer">
                                        <i className="icon-telegram"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/SudoChainAPT" target="_blank" rel="noreferrer">
                                        <i className="icon-twitter"></i>
                                    </a>
                                </li>


                            </ul>
                        </div>
                        {/* <div className="footer-logo text-center mb-45">
                            <img src={logoFImg} alt="Footer-logo" style={{ "height": "90px" , "width" : "60%" }} />
                        </div> */}
                        {/* <div className="footer-mainmenu text-center mb-20">
                            <ul>
                                <li><a href="#sec">Features</a></li>
                                <li><a href="#sec">How it works</a></li>
                                <li><a href="#sec">Token info</a></li>
                                <li><a href="#sec">About us</a></li>
                                <li><a href="#sec">Social media</a></li>
                                <li><a href="#sec">Terms of Service</a></li>
                                <li><a href="#sec">Privacy Policy</a></li>
                            </ul>
                        </div> */}
                        <div className="copyright-area text-center mb-0">
                            <div className="dsc mb-37 md-mb-25">Copyright © 2022. All Rights Reserved by Sudochain</div>
                        </div>
                        <div className="scrollup text-center">
                            <a href="#gamfi-header"><i className="icon-arrow_up"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            {/* Footer Section End  */}
        </>
    )
}
