import React, { useState, useEffect } from 'react';
import { formatPrice } from '../helper/useContracts';
import { useCommonStats } from '../hooks/useStats';
import { useWeb3React } from '@web3-react/core';
import { getStakingContract } from '../helper/useContracts';
import { getContract } from '../helper/contractHelper';
import { contract } from '../helper/contract';
import { toast } from 'react-toastify';
import stakeAbi from '../json/tokenstake.json';
import { getWeb3 } from '../helper/connectors';
import Button from 'react-bootstrap-button-loader';




export default function MyStake(props) {
    const { chainId, account, library } = useWeb3React();
    let { updater, setUpdater } = props;
    const stats = useCommonStats(updater);
    const [orders, setOrders] = useState([]);

    const [refresh, setRefersh] = useState(new Date());
    const [rewardcal, setRewardcal] = useState({ pendingReward: 0 });


    useEffect(() => {
        const getOrder = async () => {
            try {
                let stakecontract = await getStakingContract(chainId);

                const data_array = await stakecontract.methods.investorOrderIds(account).call();
                Promise.all(data_array.map(async (index) => {
                    const getdata = await stakecontract.methods.orders(index).call();
                    const pendingReward = await stakecontract.methods.pendingRewards(index).call();
                    const object = {
                        amount: getdata.amount.toString() / Math.pow(10, stats.tokenDecimal),
                        lockupDuration: getdata.lockupDuration.toString(),
                        returnPer: getdata.returnPer.toString(),
                        starttime: getdata.starttime.toString(),
                        endtime: getdata.endtime.toString(),
                        claimedReward: getdata.claimedReward.toString(),
                        claimed: getdata.claimed.toString(),
                        orderId: index,
                        pendingReward: pendingReward / Math.pow(10, stats.tokenDecimal)
                    }
                    return object;
                })).then((result) => {
                    setOrders(result);
                })
            } catch (err) {
                console.log(err);
            }
        };

        async function rewardCount() {
            try {
                let stakecontract = await getStakingContract(chainId);
                const data_array = await stakecontract.methods.investorOrderIds(account).call();
                Promise.all(data_array.map(async (index) => {
                    const pendingReward = await stakecontract.methods.pendingRewards(index).call();
                    const object = {
                        pendingReward: pendingReward / Math.pow(10, stats.tokenDecimal)
                    }
                    return object;
                })).then((result) => {
                    setRewardcal(result);

                })
            } catch (err) {
                console.log(err);
            }
        }

        if (account && chainId) {
            getOrder();
            rewardCount();
            setInterval(() => {
                rewardCount();
            }, 60000);
        }
        // eslint-disable-next-line
    }, [account, chainId, refresh, stats]);




    const handleWithdraw = async (e, orderId) => {
        e.preventDefault();

        try {

            if (account) {
                if (chainId) {
                    let tokenStakingAddress = contract[chainId] ? contract[chainId].stakingAddress : contract['default'].stakingAddress;
                    let stakeContract = getContract(stakeAbi, tokenStakingAddress, library);
                    let tx = await stakeContract.withdraw(orderId, { 'from': account });
                    const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                    toast.promise(
                        resolveAfter3Sec,
                        {
                            pending: 'Waiting for confirmation 👌',
                        }
                    )

                    var interval = setInterval(async function () {
                        let web3 = getWeb3(chainId);
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.success('success ! your last transaction is success 👍');
                                setUpdater(new Date());

                                setRefersh(new Date())
                            }
                            else if (response.status === false) {
                                toast.error('error ! Your last transaction is failed.');
                                setUpdater(new Date());

                            }
                            else {
                                toast.error('error ! something went wrong.');
                                setUpdater(new Date());

                                setRefersh(new Date())
                            }
                        }
                    }, 5000);
                }
                else {
                    toast.error('Please select Smart Chain Network !');

                }
            }
            else {
                toast.error('Please Connect Wallet!');

            }

        }
        catch (err) {
            toast.error(err.reason ? err.reason : err.message);

        }
    }

    const handleEmergencyWithdraw = async (e, orderId) => {
        e.preventDefault();

        try {

            if (account) {
                if (chainId) {
                    let tokenStakingAddress = contract[chainId] ? contract[chainId].stakingAddress : contract['default'].stakingAddress;
                    let stakeContract = getContract(stakeAbi, tokenStakingAddress, library);
                    let tx = await stakeContract.emergencyWithdraw(orderId, { 'from': account });
                    const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                    toast.promise(
                        resolveAfter3Sec,
                        {
                            pending: 'Waiting for confirmation 👌',
                        }
                    )

                    var interval = setInterval(async function () {
                        let web3 = getWeb3(chainId);
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.success('success ! your last transaction is success 👍');
                                setUpdater(new Date());

                                setRefersh(new Date())
                            }
                            else if (response.status === false) {
                                toast.error('error ! Your last transaction is failed.');
                                setUpdater(new Date());

                            }
                            else {
                                toast.error('error ! something went wrong.');
                                setUpdater(new Date());

                                setRefersh(new Date())
                            }
                        }
                    }, 5000);
                }
                else {
                    toast.error('Please select Smart Chain Network !');

                }
            }
            else {
                toast.error('Please Connect Wallet!');

            }

        }
        catch (err) {
            toast.error(err.reason ? err.reason : err.message);

        }
    }

    return (
        <div className="igo-rankging-table-list pb-140 md-pb-60 pt-55">
            <div className="container">
                <div className="igo-raking-table-content">
                    <table>
                        <tbody>
                            <tr>
                                <th>No.</th>
                                <th>Amount</th>
                                <th>Staking Date</th>
                                <th>Staking End</th>
                                <th>Reward</th>
                                <th>Actions</th>
                            </tr>
                            {orders.length > 0 ? (orders.map((row, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{parseInt(index + 1)}</td>
                                        <td>{formatPrice(row.amount)} SUDO</td>
                                        <td>{new Date(row.starttime * 1000).toString().substring(4, 25)}</td>
                                        <td>{new Date(row.endtime * 1000).toString().substring(4, 25)}</td>
                                        <td>{formatPrice(rewardcal[index] ? rewardcal[index].pendingReward : 'waiting..')}</td>
                                        <td>
                                            {row.claimed === 'false' ?
                                                (
                                                    Math.floor(new Date().getTime() / 1000.0) >= parseFloat(row.endtime) ? (
                                                        <Button varinat="none" onClick={(e) => handleWithdraw(e, row.orderId)} className="btn btn-success mr-3" type="button">Withdraw</Button>
                                                    ) :
                                                        (
                                                            <Button varinat="none" onClick={(e) => handleEmergencyWithdraw(e, row.orderId)} className="btn btn-danger" type="button">Emergency Withdraw</Button>
                                                        )
                                                )
                                                :
                                                (
                                                    <Button varinat="none" disabled={true} className="btn btn-success" type="button">claimed</Button>

                                                )

                                            }
                                        </td>

                                    </tr>
                                )
                            })

                            ) : (
                                <tr className='text-center'>
                                    <td colSpan={6}>
                                        <h5 className="text-white mt-3">You Have No Stake Record Yet.</h5>
                                    </td>
                                </tr>
                            )
                            }

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
