export const contract = { 
    // 97 : {
    //     tokenAddress : "0xf9bd9be0617cac0ff45499608aab07b683eaf020",
    //     stakingAddress : "0xdd48f90ba4279a7f70a4aa9f07dee635e3a230cd",
    //     gettokenAddress : "0xf8dd4f82fb43fe7c243fed9cded872f9eb23ee8d",
    //     getstakingAddress : "0x9a7909dc37cab73da333cf845fb0ddf0b61577fe",
    //     multicallAddress : "0xa54fe4a3dbd1eb21524cd73754666b7e13b4cb18"
    // },
    56 : {
        tokenAddress : "0x31cbd2123cbd141fe8270d7c91ba571e1489b4a3",
        stakingAddress : "0xa8426186febecc6797814ea1b607c15ac7f7aa2d",
        gettokenAddress : "0x119207c59b195ba0869dc93b725fdf4215c99a6c",
        getstakingAddress : "0x6CA633D629487f509Ba18C335201C9b798799F8A",
        multicallAddress : "0xca11bde05977b3631167028862be2a173976ca11"
    },
    'default':{
        tokenAddress : "0x31cbd2123cbd141fe8270d7c91ba571e1489b4a3",
        stakingAddress : "0xa8426186febecc6797814ea1b607c15ac7f7aa2d",
        gettokenAddress : "0x119207c59b195ba0869dc93b725fdf4215c99a6c",
        getstakingAddress : "0x6CA633D629487f509Ba18C335201C9b798799F8A",
        multicallAddress : "0xca11bde05977b3631167028862be2a173976ca11"
    }

}

