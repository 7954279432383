import React from "react";
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
import {
    NoEthereumProviderError
} from "@web3-react/injected-connector";

import { useEffect, useState } from "react";
import { injected, walletconnect, coinbaseWallet } from "../helper/connectors";
import Modal from 'react-bootstrap/Modal';
import { trimAddress } from '../helper/constant';
import useEagerConnect from '../helper/useWeb3';
import metamaskImg from '../images/metamask.png';
import walletconnectImg from '../images/walletconnect.svg';
import trustwalletImg from '../images/trustwallet.svg';
import coinbaseImg from '../images/coinbase.svg';
import connectImg from '../images/connect.png';



export const Connect = function () {
    const context = useWeb3React();
    const { connector, account, activate, deactivate, active, error } = context;
    const [show, setShow] = useState(false);

    // handle logic to recognize the connector currently being activated
    const [activatingConnector, setActivatingConnector] = useState();
    useEffect(() => {
        if (activatingConnector && activatingConnector === connector) {
            setActivatingConnector(undefined);
        }
    }, [activatingConnector, connector]);

    useEagerConnect();
    function getErrorMessage(error) {

        if (error instanceof NoEthereumProviderError) {
            return "Metamask not deteced";
        }
        if (error instanceof UnsupportedChainIdError) {
            return <span className="btn-text" onClick={() => switchNetwork(56)}>Switch BSC Network</span>;
        }

        deactivate(injected);
    }

    const activating = (connection) => connection === activatingConnector;
    const connected = (connection) => connection === connector;

    const switchNetwork = (ChainId = 56) => {
        try {
            // @ts-ignore
            window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: `0x${ChainId.toString(16)}` }]
            });
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <React.Fragment>
            <li>

                {
                    error &&
                    <button type="button" className="readon white-btn hover-shape" onClick={() => {
                        setActivatingConnector();
                    }}>
                        <span className="btn-text">{getErrorMessage(error)}</span>
                        <span className="hover-shape1"></span>
                        <span className="hover-shape2"></span>
                        <span className="hover-shape3"></span>
                    </button>
                }
                {!error &&
                    <>


                        {active && (connected(injected) || connected(walletconnect) || connected(coinbaseWallet)) &&
                            <button type="button" className="readon white-btn hover-shape" >

                                <span className="btn-text" onClick={() => {
                                    setActivatingConnector();
                                    deactivate(injected);
                                    deactivate(walletconnect);
                                    deactivate(coinbaseWallet);

                                }} >{account && trimAddress(account)}</span>


                                <span className="hover-shape1"></span>
                                <span className="hover-shape2"></span>
                                <span className="hover-shape3"></span>
                            </button>


                        }
                        {!active && (!connected(injected) || !connected(walletconnect) || !connected(coinbaseWallet)) &&
                            <button type="button" className="readon white-btn hover-shape" onClick={() => {
                                setShow(!show);
                            }}>
                                <img src={connectImg} alt="Icon" />
                                {(activating(injected) || activating(walletconnect) || activating(coinbaseWallet)) && <span className="btn-text">Connecting...</span>}
                                {(!activating(injected) || !activating(walletconnect) || !activating(coinbaseWallet)) && <span className="btn-text">Connect wallet</span>}
                                <span className="hover-shape1"></span>
                                <span className="hover-shape2"></span>
                                <span className="hover-shape3"></span>
                            </button>
                        }
                    </>
                }
            </li>

            <Modal
                size="lg"
                show={show}
                onHide={() => setShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton={true}>
                    <h5 style={{ "margin": "0" }}>Connect Wallet</h5>
                </Modal.Header>
                <Modal.Body >
                    <p className="mb-20">Please select a wallet to connect to this marketplace</p>
                    <div className="connect-section">
                        <ul className="heading-list">
                            <li onClick={() => {
                                activate(injected);
                                setShow(false);
                            }}>
                                <button className="connect-btn">
                                    <span><img src={metamaskImg} alt="Meta-mask-Im" /></span>MetaMask
                                </button>
                            </li>
                            <li onClick={() => {
                                activate(coinbaseWallet);
                                setShow(false);
                            }}>
                                <button className="connect-btn">
                                    <span><img src={coinbaseImg} alt="Coinbase-Im" /></span>Coinbase
                                </button>
                            </li>
                            <li onClick={() => {
                                activate(injected);
                                setShow(false);
                            }}>
                                <button className="connect-btn">
                                    <span><img src={trustwalletImg} alt="Trust-Ima" /></span>Trust Wallet
                                </button>
                            </li>
                            <li onClick={() => {
                                activate(walletconnect);
                                setShow(false);
                            }}>
                                <button className="connect-btn">
                                    <span><img src={walletconnectImg} alt="Wallet-Im" /></span>WalletConnect
                                </button>
                            </li>
                        </ul>
                    </div>
                    <p>By connecting your wallet, you agree to our <a href="#sec">
                        <span className="modal-title">Terms of Service </span></a>and our <a href="#sec">
                            <span className="modal-title"> Privacy Policy</span></a>.</p>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
};

export default Connect;