import React from 'react';
import { Link } from "react-router-dom"
import Connect from './Connect';
import MobileConnect from './MobileConnect';
import logoImg from '../images/logo.png';
import pancakeImg from '../images/pancake.png';

export default function Header() {

    const toggleNav = () => {
        document.body.classList.add('nav-expanded');
    }

    const toggleNavClose = () => {
        document.body.classList.remove('nav-expanded');
    }


    return (
        <React.Fragment>

            {/* <section className="loader_first">
                <div className="circular-spinner"></div>
            </section> */}
            {/* Preloader area End here */}

            {/* Header Start */}
            <header id="gamfi-header" className="gamfi-header-section transparent-header">
                <div className="menu-area menu-sticky">
                    <div className="container">
                        <div className="heaader-inner-area d-flex justify-content-between align-items-center">
                            <div className="gamfi-logo-area d-flex justify-content-between align-items-center">
                                <div className="logo">
                                    <Link to="/">
                                        <img src={logoImg} alt="logo" style={{ "height": "80px" }} />
                                    </Link>
                                </div>
                                <div className="header-menu">
                                    <ul className="nav-menu">
                                        <li>
                                            <a href="https://www.thesudochain.com/" target="_blank" rel="noreferrer">Home</a>
                                        </li>
                                        <li><a href="#sec">Staking</a>
                                            <ul className="sub-menu">
                                                <li><Link to="/">APT Staking</Link></li>
                                                <li><Link to="/get-stake">GET Staking</Link></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="gamfi-btn-area">
                                <ul>
                                    <li>
                                        <a href="#sec" id="nav-expander" onClick={(e) => toggleNav(e)} className="nav-expander bar">
                                            <div className="bar">
                                                <span className="dot1"></span>
                                                <span className="dot2"></span>
                                                <span className="dot3"></span>
                                            </div>
                                        </a>
                                    </li>
                                    <li className="buy-token">
                                        <a className="readon black-shape" href="#sec">
                                            <span className="btn-text">Buy Token </span>
                                            <i className="icon-arrow_down"></i>
                                            <span className="hover-shape1"></span>
                                            <span className="hover-shape2"></span>
                                            <span className="hover-shape3"></span>
                                        </a>
                                        <ul>
                                            <li>
                                                <a target="_blank" rel="noreferrer" href="https://pancakeswap.finance/swap?outputCurrency=0x31cBD2123CBd141fE8270d7c91bA571E1489b4A3">
                                                    <img src={pancakeImg} alt="pancake" /> PancakeSwap
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                    <Connect />
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Canvas Mobile Menu start  */}
                <nav className="right_menu_togle mobile-navbar-menu" id="mobile-navbar-menu">
                    <div className="close-btn">
                        <a href='#sec' onClick={(e) => toggleNavClose(e)} className="nav-close">
                            <div className="line">
                                <span className="line1"></span>
                                <span className="line2"></span>
                            </div>
                        </a>
                    </div>
                    <div className="sidebar-logo mb-30">
                        <Link to="/"><img src={logoImg} alt="logo-dark" style={{ "height": "60px" }} /></Link>
                    </div>
                    <ul className="nav-menu">
                        <ul className="nav-menu">
                            <li><a href="https://www.thesudochain.com/" target="_blank" rel="noreferrer">Home</a></li>
                            <li><a href="#sec">Staking</a>
                                <ul className="sub-menu">
                                    <li><Link to="/">APT Staking</Link></li>
                                    <li><Link to="/get-stake">GET Staking</Link></li>
                                </ul>
                            </li>

                            <li><a href="#sec">Buy Token</a>
                                <ul className="sub-menu">
                                    <li><a target="_blank" rel="noreferrer" href="https://pancakeswap.finance/swap?outputCurrency=0x31cBD2123CBd141fE8270d7c91bA571E1489b4A3">
                                        <img src={pancakeImg} alt="pancake" /> PancakeSwap
                                    </a></li>

                                </ul>
                            </li>
                        </ul>
                        <MobileConnect />
                    </ul>
                </nav>

            </header>

        </React.Fragment>
    )
}
